/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Leaflet */
@import "~leaflet/dist/leaflet.css";

:root {
    ion-card-content {
        font-size: 1.2em;
    }
    
    @media only screen and (min-width: 768px) {
        ion-card-content {
            font-size: 1.5em;
        }
    
        ion-list ion-item p {
            font-size: 1.1em;
            line-height: 1.5em;
        }
    
        ion-list ion-item h2 {
            font-size: 1.2em;
            line-height: 1.4em;
        }
    }    
}

.toolbar-title {
    white-space: normal !important;
}

.sc-ion-label-md-s h3, .sc-ion-label-md-s h4, .sc-ion-label-md-s h5, .sc-ion-label-md-s h6 {
    font-size: 16px;
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
    white-space: normal !important;
}

@media (prefers-color-scheme: dark) {
    .item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
        color: #fff;
    }
}

body.dark {
    .item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
        color: #fff;
    }
}